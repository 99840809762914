import React, { useEffect } from "react";
import { gapi } from "gapi-script";
import { Button } from "@mui/material";

const CLIENT_ID =
  "469239643871-99m5231a45imq4pgncssn9s10gprkkov.apps.googleusercontent.com";
const DISCOVERY_DOCS = [
  "https://people.googleapis.com/$discovery/rest?version=v1",
];
const SCOPES = "https://www.googleapis.com/auth/contacts.readonly";

export default function GetAllContacts() {
  useEffect(() => {
    function start() {
      gapi.client
        .init({
          apiKey: "AIzaSyBwFgH_CXCeLGH2Icranyiqksgf8dt1G10",
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES,
        })
        .then(() => {
          // Listen for sign-in state changes
          gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);
          updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
        });
    }

    gapi.load("client:auth2", start);
  }, []);

  const updateSigninStatus = (isSignedIn) => {
    if (isSignedIn) {
      listContacts();
    }
  };

  const handleSignIn = () => {
    gapi.auth2.getAuthInstance().signIn();
  };

  const handleSignOut = () => {
    gapi.auth2.getAuthInstance().signOut();
  };

  const listContacts = () => {
    gapi.client.people.people.connections
      .list({
        resourceName: "people/me",
        pageSize: 10,
        personFields: "names,emailAddresses",
      })
      .then((response) => {
        const connections = response.result.connections;
        if (connections) {
          console.log("Contacts:", connections);
        } else {
          console.log("No connections found.");
        }
      })
      .catch((err) => console.error("Error fetching contacts:", err));
  };
  return (
    <div>
      <h1>Get All Contacts</h1>
      <div className="my-4">
        <Button variant="contained" onClick={handleSignIn}>
          Sign In
        </Button>
        <Button variant="outlined" color="error" onClick={handleSignOut}>
          Sign Out
        </Button>
      </div>
    </div>
  );
}
