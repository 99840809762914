export const brandsList = [
  {
    _id: 1,
    brandName: "Premium Dream Night Sandal & Citronella Herbal Incense Stick",
    slogan: "मच्छरों का यमराज",
    stabilizedYear: 1998,
    likes: 489,
    brandLogo: "/assets/images/dreamNightBanner.webp",
    overview:
      "Dream Night Herbal Incense Sticks offer an exceptional way to create a mosquito-free environment while enriching your space with a pleasant, calming aroma. Expertly crafted from natural ingredients, these incense sticks are perfect for unwinding after a long day, hosting outdoor gatherings, or simply ensuring that your living space stays comfortable and pest-free. Dream Night provides dual benefits of mosquito protection and an inviting fragrance, making it an essential addition to any household.",
    keyFeatures: [
      "Dual Benefits - Combines divine sandalwood fragrance with citronella's mosquito-repelling properties.",
      "100% Natural Ingredients - Made from herbal extracts for a safe and chemical-free experience.",
      "Long-Lasting Burn - Each stick burns evenly and consistently for hours of protection.",
      "Mosquito Repellent - Keeps mosquitoes and insects at bay while purifying the air.",
      "Refreshing Fragrance - Fills your space with a calming and uplifting aroma.",
      "Eco-Friendly - Made from sustainable materials to ensure minimal environmental impact.",
      "Suitable for All Occasions - Ideal for homes, offices, temples, and outdoor spaces.",
    ],
    whyChooseUs: [
      "Natural and Safe - Free from harmful chemicals and artificial additives.",
      "Effective Mosquito Control - Protects your family from mosquitoes naturally.",
      "Aromatic Bliss - Creates a serene atmosphere with sandalwood’s soothing scent.",
      "Trusted Quality - A brand known for delivering reliable and effective products.",
      "Eco-Conscious - Committed to sustainability and environmentally friendly practices.",
      "Affordable Protection - High-quality incense sticks at a pocket-friendly price.",
      "Multi-Use - Perfect for spiritual rituals, relaxation, and everyday mosquito protection.",
    ],
    footer:
      "Experience Tranquility and Protection Dream Night Herbal Incense Sticks are the ideal choice for those who value a harmonious living space, free from the annoyance of mosquitoes and full of soothing, natural fragrances. Elevate your home’s comfort and ensure your peace of mind with every stick.",
  },

  {
    _id: 2,
    brandName: "Black Panther Herbal Incense Stick",
    slogan: "मच्छरों का यमराज",
    stabilizedYear: 1998,
    likes: 489,
    brandLogo: "/assets/images/blackPantherBanner.webp",
    overview:
      "Black Panther Herbal Incense Sticks are expertly designed to offer unmatched mosquito protection while infusing your home with an earthy, invigorating scent. Made from a unique blend of natural herbs and essential oils, these incense sticks create an effective barrier against mosquitoes and other insects. Perfect for evenings, relaxation time, or outdoor gatherings, Black Panther brings powerful protection and a revitalizing aroma to any space.",
    keyFeatures: [
      "Powerful Mosquito Repellent - Naturally keeps mosquitoes at bay with its herbal blend.",
      "100% Natural Ingredients - Made with pure, safe herbs for a chemical-free experience.",
      "Long-Lasting - Burns evenly for hours, offering extended protection.",
      "Fresh and Bold Aroma - Fills your home with a refreshing, invigorating fragrance.",
      "Eco-Friendly - Crafted from sustainable materials to protect both your home and the environment.",
      "Non-Toxic - Safe for children and pets, offering a natural alternative to chemicals.",
      "Versatile Use - Perfect for indoor and outdoor use, including rituals, relaxation, and protection.",
    ],
    whyChooseUs: [
      "Herbal Protection - Free from harmful chemicals, relying on nature's power to protect you.",
      "Effective Mosquito Control - Proven to repel mosquitoes and other insects naturally.",
      "Bold Fragrance - A fresh and energizing aroma that creates a welcoming environment.",
      "Trusted Quality - A brand known for delivering effective, safe, and reliable products.",
      "Eco-Friendly Approach - Made with sustainable practices to ensure environmental protection.",
      "Affordable Excellence - Premium quality at a price that’s accessible for everyone.",
      "Multi-Functional - Ideal for relaxation, spiritual rituals, and keeping your space mosquito-free.",
    ],
    footer:
      "Unleash the Power of Nature Choose Black Panther Herbal Incense Sticks for a dynamic blend of effective mosquito defense and revitalizing fragrance. Create a safe, pleasant environment and enjoy peace of mind with every use.",
  },

  {
    _id: 3,
    brandName:
      "Metro Hygienic Disinfectant Perfumed Fluid & Home Care Products",
    slogan: "स्वच्छ भारत, स्वस्थ भारत",
    stabilizedYear: 1998,
    likes: 489,
    brandLogo: "/assets/images/metroHygenicBanner.webp",
    overview:
      "MetroHygienic is your trusted partner for maintaining a spotless, fresh, and hygienic environment. Our comprehensive range of products includes powerful floor cleaners, refreshing room fresheners, effective toilet cleaners, gentle hand washes, specialized copper & brass cleaners, car wash shampoos, and efficient tiles cleaners. Each product is meticulously formulated to provide outstanding cleaning power while being safe for use in any home or commercial space. With MetroHygienic, cleaning becomes not just a task but a rejuvenating experience.",
    keyFeatures: [
      "Powerful Disinfection - Effectively eliminates bacteria, viruses, and germs to ensure a hygienic environment.",
      "Pleasant Fragrance - Leaves behind a long-lasting, fresh, and fragrant scent after every use.",
      "Multipurpose Use - Ideal for cleaning floors, surfaces, bathrooms, kitchens, and even laundry.",
      "Safe for All - Non-toxic and safe for use around children and pets.",
      "Eco-Friendly - Formulated with environmentally responsible ingredients for a sustainable future.",
      "Quick Action - Starts working immediately, providing rapid germ-kill and cleanliness.",
      "Affordable Hygiene - Premium hygiene solution at a price that suits your budget.",
    ],
    whyChooseUs: [
      "Trusted Brand - Metro is known for delivering high-quality, reliable hygiene solutions.",
      "Effective Germ Protection - Our disinfectant fluid ensures thorough protection from harmful microorganisms.",
      "Refreshing Aroma - Transforms your space with a fresh and inviting fragrance.",
      "Safe & Non-Toxic - Formulated to be safe for all family members, including children and pets.",
      "Sustainable Practices - Committed to eco-friendly ingredients and packaging.",
      "Comprehensive Cleaning - A versatile product that addresses all your home care needs.",
      "Value for Money - High-quality performance at an affordable price.",
    ],
    footer:
      "Elevate Your Cleaning Standards With MetroHygienic, transform your cleaning routine into a seamless and satisfying experience. Achieve sparkling floors, a fresh atmosphere, spotless toilets, shiny metalware, and more with our versatile and effective products. Trust MetroHygienic to keep your spaces hygienic and welcoming.",
  },

  {
    _id: 4,
    brandName: "Palamu Spices",
    slogan: "स्वाद के साथ सेहत भी",
    stabilizedYear: 1998,
    likes: 489,
    brandLogo: "/assets/images/plamuSpicesBanner.webp",
    overview:
      "Experience the perfect blend of स्वाद (taste) and सेहत (health). Our product is designed to offer unmatched quality with a rich, delicious taste while being a powerhouse of nutrition. Ideal for health-conscious individuals and families who believe in wholesome living, this is the choice you’ve been waiting for!",
    keyFeatures: [
      "Rich in Nutrients - Packed with essential vitamins and minerals for a healthier you.",
      "Unmatched Taste - A flavor profile that delights your taste buds with every bite.",
      "Natural Ingredients - Made from 100% natural and organic components.",
      "No Preservatives - We ensure pure goodness without any artificial additives.",
      "Easy to Use - Perfect for busy lifestyles, quick and hassle-free to prepare.",
      "Suitable for All Ages - Loved equally by kids and adults.",
      "Eco-Friendly Packaging - Sustainable packaging to contribute to a greener planet.",
    ],
    whyChooseUs: [
      "Commitment to Quality - We prioritize quality in every step of our production.",
      "Health-Focused - Designed to cater to your body’s nutritional needs without compromising on taste.",
      "Affordability with Value - Premium quality at a price that fits your budget.",
      "Trusted by Families - A trusted product for homes across the nation.",
      "Innovative Recipes - Inspired by traditional methods with a modern twist.",
      "Customer-Centric - Your satisfaction is at the core of what we do.",
      "Proven Track Record - Backed by rave reviews and repeat customers.",
    ],
    footer:
      "Rediscover the joy of eating with our product – स्वाद के साथ सेहत भी! Try it today and join thousands who have embraced taste and health in perfect harmony. Because you deserve the best!",
  },

  {
    _id: 5,
    brandName: "Khadakpur Masala",
    slogan: "स्वाद के साथ सेहत भी",
    stabilizedYear: 2007,
    likes: 489,
    brandLogo: "/assets/images/kharagpur-masala-banner.webp",
    overview:
      "Bring home the authentic taste of India with Khadakpur Masala. Carefully crafted with premium quality spices, our masala blends are designed to add rich flavors and vibrant aromas to your dishes. Whether you're cooking traditional recipes or experimenting with modern cuisine, Khadakpur Masala guarantees unmatched quality and taste that turns every meal into a feast!",
    keyFeatures: [
      "Authentic Flavor - Sourced from the finest spices to bring traditional Indian taste.",
      "Rich Aroma - Adds a captivating aroma to your dishes that will tantalize your senses.",
      "100% Natural Ingredients - Free from artificial additives, colors, or preservatives.",
      "Versatile Use - Perfect for curries, marinades, gravies, and snacks.",
      "Consistent Quality - Blended with precision for uniform taste in every pack.",
      "Hygienically Processed - Ensuring purity and safety at every stage of production.",
      "Eco-Friendly Packaging - Sustainably packed to preserve freshness and reduce waste.",
    ],
    whyChooseUs: [
      "Premium Ingredients - We use only the finest hand-picked spices for our blends.",
      "Heritage Taste - Capturing the essence of traditional Indian culinary expertise.",
      "Health First - Free from harmful chemicals or fillers to ensure a healthy experience.",
      "Affordable Luxury - High-quality masala at a price you’ll love.",
      "Trusted Brand - Loved and recommended by households across the country.",
      "Innovative Recipes - Perfectly balanced blends to enhance both classic and modern dishes.",
      "Customer Satisfaction - Your happiness is our priority, backed by positive reviews.",
    ],
    footer:
      "Transform your cooking with Khadakpur Masala – the true essence of Indian flavors. Make every meal a celebration of taste and tradition with our premium spice blends. **Taste the difference today!**",
  },

  {
    _id: 6,
    brandName: "Asha Bal Gopal Puja Ghee",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    stabilizedYear: 2007,
    likes: 489,
    brandLogo: "/assets/images/bal-gopal-ghee-banner.webp",
    overview:
      "Asha Bal Gopal Puja Ghee is the epitome of purity and divinity, crafted to elevate your spiritual rituals and culinary experiences. Made from the finest milk, our ghee is prepared using traditional methods to preserve its rich aroma, golden color, and unmatched taste. Ideal for pujas, offerings, and daily use, it adds a sacred touch to every moment of your life.",
    keyFeatures: [
      "100% Pure and Natural - Made from premium quality milk for authentic taste.",
      "Traditional Preparation - Crafted using ancient methods to retain its original essence.",
      "Divine Aroma - Fills your home with a soothing and sacred fragrance.",
      "Rich in Nutrients - Packed with essential vitamins and good fats for a healthy lifestyle.",
      "Perfect for Rituals - Ideal for lamps, offerings, and other sacred ceremonies.",
      "Long Shelf Life - Retains freshness and quality for an extended period.",
      "Eco-Friendly Packaging - Sustainably packed to maintain purity and reduce environmental impact.",
    ],
    whyChooseUs: [
      "Unmatched Purity - We prioritize quality, ensuring ghee that is free from any adulteration.",
      "Divine Connection - Enhances the spiritual significance of your rituals.",
      "Health Benefits - A great source of energy and a boost to overall wellness.",
      "Authentic Taste - Adds a rich and traditional flavor to your meals and desserts.",
      "Trusted by Families - A name synonymous with purity and quality in households.",
      "Premium Ingredients - Made from the finest milk sourced from healthy and well-cared-for cows.",
      "Value for Money - Superior quality ghee at a price that ensures satisfaction.",
    ],
    footer:
      "Experience the divine purity of Asha Bal Gopal Puja Ghee – your perfect companion for spirituality and health. Whether it’s for sacred rituals or enhancing the flavor of your meals, trust Asha Bal Gopal for uncompromised quality. **Purity you can trust, blessings you can feel.**",
  },

  {
    _id: 7,
    brandName: "Asha Govardhan Puja Ghee",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    stabilizedYear: 2007,
    likes: 489,
    brandLogo: "/assets/images/goverdhan-ghee-banner.webp",
    overview:
      "Asha Govardhan Puja Ghee is crafted to honor the sacred traditions of Govardhan Puja and enhance your spiritual and culinary practices. Made from premium milk and processed using time-honored methods, it embodies purity, divine aroma, and exceptional taste. Whether for pujas, offerings, or daily cooking, Asha Govardhan Puja Ghee ensures an experience filled with authenticity and devotion.",
    keyFeatures: [
      "100% Pure and Natural - Sourced from the finest milk for unmatched quality.",
      "Rich Aroma - Fills your space with a sacred and soothing fragrance during rituals.",
      "Traditionally Crafted - Made using ancient methods to preserve its rich essence.",
      "Nutrient-Rich - Packed with vitamins and healthy fats for optimal well-being.",
      "Perfect for Rituals - Essential for Govardhan Puja and other sacred ceremonies.",
      "Authentic Flavor - Enhances the taste of your meals and traditional sweets.",
      "Long Shelf Life - Retains freshness, aroma, and taste over an extended period.",
    ],
    whyChooseUs: [
      "Symbol of Purity - Ensures ghee that is free from any adulteration or chemicals.",
      "Sacred Connection - Adds a divine touch to your spiritual rituals.",
      "Boosts Health - Provides natural energy and supports overall wellness.",
      "Cultural Heritage - Honors the rich traditions of Govardhan Puja with authenticity.",
      "Trusted by Devotees - A name synonymous with quality and devotion in households.",
      "Finest Ingredients - Made from premium milk from healthy and well-nurtured cows.",
      "Value You Can Trust - Exceptional quality at an affordable price.",
    ],
    footer:
      "Celebrate the sacred traditions of Govardhan Puja with Asha Govardhan Puja Ghee. Perfect for rituals, offerings, and cooking, it is a symbol of purity, devotion, and unmatched taste. **Bring blessings to your home and meals with Asha Govardhan Puja Ghee today!**",
  },

  {
    _id: 8,
    brandName: "Asha Til Ganga Arti",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    stabilizedYear: 2007,
    likes: 489,
    brandLogo: "/assets/images/til-ganga-aarti.webp",
    brandName: "Asha Til Ganga Arti",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    overview:
      "Asha Til Ganga Arti is a sacred offering designed to bring divinity and serenity to your spiritual rituals. Crafted with pure sesame oil and infused with tradition, it ensures a bright and steady flame for your Ganga Aarti and other sacred ceremonies. Celebrate devotion with unmatched purity and let your prayers resonate with divine blessings.",
    keyFeatures: [
      "100% Pure Sesame Oil - Ensures a clean and bright flame for your rituals.",
      "Divine Aroma - Enhances the spiritual ambiance with a calming fragrance.",
      "Traditionally Crafted - Prepared with methods that honor sacred customs.",
      "Steady and Long-Lasting Flame - Ideal for Ganga Aarti and other pujas.",
      "Eco-Friendly Packaging - Sustainably designed to preserve quality and reduce waste.",
      "Safe and Hygienic - Processed with care to maintain purity and safety.",
      "Multipurpose Use - Perfect for aarti, diyas, and other religious purposes.",
    ],
    whyChooseUs: [
      "Commitment to Purity - Ensuring oil that is free from any impurities.",
      "Enhanced Spirituality - Designed to elevate the sacredness of your rituals.",
      "Trusted by Devotees - A household name for quality and devotion.",
      "Eco-Conscious Approach - Packaged with sustainability in mind.",
      "Rich in Tradition - Prepared to honor the essence of Ganga Aarti.",
      "Superior Quality - Sourced and processed with the highest standards.",
      "Affordable and Reliable - Premium quality at a price that suits your budget.",
    ],
    footer:
      "Elevate your Ganga Aarti and rituals with Asha Til Ganga Arti – a symbol of devotion, purity, and tradition. Let the sacred flame of your prayers shine brightly and bring divine blessings into your life. **Because every prayer deserves the best!**",
  },

  {
    _id: 9,
    brandName: "Asha Til Laxmi",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    stabilizedYear: 2007,
    likes: 489,
    brandLogo: "/assets/images/til-laxmi.webp",
    brandName: "Asha Til Laxmi",
    slogan: "त्वमेव सर्वम् मम् देव देव",
    overview:
      "Asha Til Laxmi is a symbol of purity and prosperity, crafted with 100% pure sesame oil to honor the traditions of worship and wellness. Ideal for pujas, lighting diyas, and enhancing spiritual ceremonies, it embodies devotion and reverence. Bring home the essence of purity and invite divine blessings with Asha Til Laxmi.",
    keyFeatures: [
      "100% Pure Sesame Oil - Made from the finest sesame seeds for unmatched quality.",
      "Rich Aroma - Fills your space with a soothing and divine fragrance.",
      "Bright and Long-Lasting Flame - Perfect for diyas and sacred rituals.",
      "Traditionally Processed - Honoring the customs of worship and spirituality.",
      "Eco-Friendly Packaging - Designed to preserve purity and support sustainability.",
      "Safe and Hygienic - Processed under strict quality controls to maintain purity.",
      "Multipurpose Use - Suitable for aarti, rituals, and everyday spiritual practices.",
    ],
    whyChooseUs: [
      "Commitment to Purity - Ensuring oil that is free from additives or impurities.",
      "Sacred and Spiritual - Enhances the sanctity of your pujas and offerings.",
      "Trusted by Families - A brand synonymous with devotion and quality.",
      "Eco-Conscious Packaging - Promoting sustainability with every purchase.",
      "Rooted in Tradition - Prepared to honor the essence of Indian spirituality.",
      "Uncompromised Quality - Sourced and processed to meet the highest standards.",
      "Affordable Excellence - Premium quality at a price that respects your budget.",
    ],
    footer:
      "Celebrate devotion and prosperity with Asha Til Laxmi – the perfect companion for your spiritual practices. Let the sacred glow of your prayers bring divine blessings and positivity to your life. **Purity for your worship, blessings for your home!**",
  },
];
