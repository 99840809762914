"use client";
import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { BiMenu } from "react-icons/bi";
import { FaArrowRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { IoCall, IoLogoWhatsapp } from "react-icons/io5";

const drawerWidth = 240;
const navItems = [
  "FMCG Company",
  "IT Company",
  "Wealth Mgmt. Company",
  "Social Apps",
  // "Contact Us",
];

export default function Header() {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showHeader, setShowHeader] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 80) {
        // Scrolling down and scroll position is greater than 80
        setShowHeader(false);
      } else if (currentScrollY < lastScrollY && currentScrollY > 80) {
        // Scrolling up and scroll position is greater than 80
        setShowHeader(true);
      } else if (currentScrollY <= 80) {
        // When scrolling back to top (<= 80), always show the header
        setShowHeader(true);
      }

      lastScrollY = currentScrollY;
      setScrollPosition(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollToElementId = (key) => {
    switch (key) {
      case "FMCG Company":
        navigate("/");
        const brandsElement = document.getElementById("FMCG Company");
        if (brandsElement) {
          const yOffset = -60; // Adjust this value to set the top margin
          const yPosition =
            brandsElement.getBoundingClientRect().top +
            window.pageYOffset +
            yOffset;

          window.scrollTo({
            top: yPosition,
            behavior: "smooth",
          });
        }
        break;

      case "Wealth Mgmt. Company":
        navigate("/");
        setTimeout(() => {
          const wealthElement = document.getElementById("Wealth Mgmt. Company");
          if (wealthElement) {
            const yOffset = -60; // Adjust this value to set the top margin
            const yPosition =
              wealthElement.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: "smooth",
            });
          }
        }, 100);
        break;

      case "IT Company":
        navigate("/");
        setTimeout(() => {
          const element1 = document.getElementById("IT Company");
          if (element1) {
            const yOffset = -60; // Adjust this value to set the top margin
            const yPosition =
              element1.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: "smooth",
            });
          }
        }, 100);
        break;

      case "Social Apps":
        navigate("/");
        setTimeout(() => {
          const element2 = document.getElementById("social-services");
          if (element2) {
            const yOffset = -60; // Adjust this value to set the top margin
            const yPosition =
              element2.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: "smooth",
            });
          }
        }, 100);
        break;

      case "Contact Us":
        navigate("/");
        setTimeout(() => {
          const element3 = document.getElementById("contact-us");
          if (element3) {
            const yOffset = -60; // Adjust this value to set the top margin
            const yPosition =
              element3.getBoundingClientRect().top +
              window.pageYOffset +
              yOffset;

            window.scrollTo({
              top: yPosition,
              behavior: "smooth",
            });
          }
        }, 100);
        break;

      default:
        break;
    }
  };

  ///////////////////////////////////
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <div className="ml-4 flex gap-4 items-center">
          <div className="flex gap-4 items-center">
            <img
              className="h-12"
              src="/assets/images/signature.png"
              alt="founder"
            />
          </div>
        </div>
      </Typography>
      <Divider />
      <List>
        {navItems?.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton
              sx={{ textAlign: "left" }}
              onClick={() => handleScrollToElementId(item)}
            >
              <div className="flex items-center gap-4 text-black hover:bg-gradient-to-tr from-primary via-rose-600 to-secondary hover:bg-clip-text hover:text-transparent">
                <FaArrowRight className="text-rose-600" />
                <div>{item}</div>
              </div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <div
        className={`z-50 fixed top-0 left-0 right-0 transition-transform duration-500 ease-in-out ${
          showHeader ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar
            component="nav"
            sx={{
              bgcolor: "#fff",
              position: "static",
            }}
          >
            <Toolbar>
              <Box
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{ mr: 2, display: { lg: "none" } }}
                className="flex items-center w-full"
              >
                <IconButton onClick={handleDrawerToggle}>
                  <BiMenu className="text-rose-600" />
                </IconButton>
                <div className="ml-0 flex gap-4 items-center w-full justify-between">
                  <div className="flex items-center">
                    <img
                      className="h-8"
                      src="/assets/images/signature.png"
                      alt="founder"
                    />
                  </div>
                </div>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", lg: "block" } }}>
                <div className="flex gap-4 items-center">
                  <img
                    className="h-12"
                    src="/assets/images/signature.png"
                    alt="founder"
                  />
                </div>
              </Box>
              <Box sx={{ display: { xs: "none", lg: "block" } }}>
                {navItems?.map((item) => (
                  <Button
                    key={item}
                    onClick={() => handleScrollToElementId(item)}
                  >
                    <div className="hover:bg-gradient-to-tr duration-500 text-black from-primary via-rose-600 to-secondary hover:bg-clip-text hover:text-transparent">
                      {item}
                    </div>
                  </Button>
                ))}
              </Box>

              <div className="flex items-center gap-4">
                <div className="">
                  <a
                    className="p-1.5 text-sm px-2 rounded-lg sm:text-white text-primary sm:bg-gradient-to-tr from-primary to-primary duration-500 flex items-center gap-2 sm:border border-white"
                    href="tel:+918789857503"
                  >
                    <IoCall size={22} />
                    <p className="hidden sm:block text-nowrap">Call Now</p>
                  </a>
                </div>

                <div className="">
                  <a
                    className="p-1.5 text-sm px-2 rounded-lg sm:text-white text-green-800 sm:bg-gradient-to-tr from-green-800 to-green-600 duration-500 flex items-center gap-2 sm:border border-white"
                    // href="https://api.whatsapp.com/send?phone=916534796294"
                    href="https://wa.me/+918789857503"
                    target="_blank"
                  >
                    <IoLogoWhatsapp size={22} />
                    <p className="hidden sm:block">WhatsApp</p>
                  </a>
                </div>
              </div>
            </Toolbar>
          </AppBar>
          <nav>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", sm: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        </Box>
      </div>
    </>
  );
}
