import React from "react";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { motion } from "framer-motion";
import { Paper } from "@mui/material";
import { socialMediaUrls } from "../../jsonFiles/socialMediaUrls";

export default function Hero() {
  return (
    <motion.div
      className="flex items-center"
      id="portfolio"
      initial={{ opacity: 0, scale: 1, y: 0 }}
      whileInView={{ opacity: 1, scale: 1, y: 0 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
    >
      <Paper
        sx={{ borderRadius: 3 }}
        className="md:grid grid-cols-6 gap-4 items-center"
      >
        {/* First col */}
        <div className="col-span-4 p-6">
          <div className="sm:w-[80%]">
            <div>
              <p className="text-sm text-gray-400 text-center md:text-left">
                Welcome to my world
              </p>
              <h1 className="text-center md:text-left md:text-3xl text-2xl mt-4 font-bold bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent">
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  Meet
                </b>{" "}
                <b className="font-bold bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent">
                  Rohit Tiwary,{" "}
                </b>
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  Founder
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  of
                </b>{" "}
                <b className="font-bold bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent">
                  Asha Industries,
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  Fewmax
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  and
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  Asha
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  Associates
                </b>{" "}
                <b className="font-bold bg-gradient-to-t from-primary via-rose-600 to-secondaryDark bg-clip-text text-transparent">
                  & CO
                </b>{" "}
              </h1>
            </div>

            <div>
              <p className="mt-6 text-sm text-gray-400 font-normal">
                I am Rohit Tiwary, the Founder of Asha Industries, Fewmax, and
                Asha Associates & CO, a company built on the foundation of hard
                work, passion, and unwavering commitment to quality. Over the
                years, I’ve learned that success lies in understanding customer
                needs, building relationships, and evolving with market demands.
              </p>

              <p className="mt-2 text-sm text-gray-400 font-normal">
                With a background in engineering and business development, I
                envisioned Asha Industries as a platform for innovation and
                progress. Today, it stands as a trusted name in delivering
                solutions that address real-world challenges with cutting-edge
                manufacturing and technology.
              </p>

              <p className="mt-2 text-sm text-gray-400 font-normal">
                Fewmax and Asha Associates & CO further extend this vision by
                offering impactful solutions and strategic support, empowering
                businesses to thrive. Together, these ventures represent my
                dedication to quality, trust, and progress.
              </p>
            </div>
          </div>

          {/* Follow me */}
          <div className="mt-10 text-center md:text-left ">
            <p className="bg-gradient-to-b from-primary via-rose-600 to-secondary bg-clip-text text-transparent text-sm">
              Follow me
            </p>

            <div className="mt-2 flex gap-4 flex-wrap h-[58px] items-end justify-center md:justify-start">
              <a
                href={
                  socialMediaUrls?.find((e) => e?.title === "instagram")?.url
                }
                target="_blank"
                rel="noreferrer"
              >
                <FaSquareInstagram className="p-1 w-8 h-8 hover:mb-2 duration-500 bg-gradient-to-b from-primary via-rose-600 to-secondary rounded-lg shadow-md shadow-black/50 cursor-pointer text-white bg-primary hover:bg-secondary" />
              </a>
              <a
                href={
                  socialMediaUrls?.find((e) => e?.title === "facebook")?.url
                }
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebookF className="p-1 w-8 h-8 hover:mb-2 duration-500 bg-gradient-to-b from-primary via-rose-600 to-secondary rounded-lg shadow-md shadow-black/50 cursor-pointer text-white bg-primary hover:bg-secondary" />
              </a>
              <a
                href={
                  socialMediaUrls?.find((e) => e?.title === "linkedin")?.url
                }
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedinIn className="p-1 w-8 h-8 hover:mb-2 duration-500 bg-gradient-to-b from-primary via-rose-600 to-secondary rounded-lg shadow-md shadow-black/50 cursor-pointer text-white bg-primary hover:bg-secondary" />
              </a>
              <a
                href={socialMediaUrls?.find((e) => e?.title === "twitter")?.url}
                target="_blank"
                rel="noreferrer"
              >
                <BsTwitterX className="p-1 w-8 h-8 hover:mb-2 duration-500 bg-gradient-to-b from-primary via-rose-600 to-secondary rounded-lg shadow-md shadow-black/50 cursor-pointer text-white bg-primary hover:bg-secondary" />
              </a>
              <a
                href={socialMediaUrls?.find((e) => e?.title === "youtube")?.url}
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube className="p-1 w-8 h-8 hover:mb-2 duration-500 bg-gradient-to-b from-primary via-rose-600 to-secondary rounded-lg shadow-md shadow-black/50 cursor-pointer text-white bg-primary hover:bg-secondary" />
              </a>
            </div>
          </div>
        </div>

        {/* Second col */}
        <div className="col-span-2 mt-4 md:mt-0 p-6">
          <div className="bg-gradient-to-tr from-secondary via-rose-600 to-primary shadow-sm border-t border-primaryLight shadow-secondary rounded-xl p-4 pb-0">
            <img
              className="h-fit mx-auto"
              src="/assets/images/rohit-tiwary-hero.webp"
              alt="self-img"
            />
          </div>
        </div>
      </Paper>
      <div className="p-[1px] my-20 bg-black/30" />
    </motion.div>
  );
}
