export const socialMediaUrls = [
  {
    title: "facebook",
    url: "https://www.facebook.com/profile.php?id=100093996833036",
  },
  {
    title: "instagram",
    url: "https://www.instagram.com/ashaindustries8/profilecard/?igsh=MWpwemY1b2ZxOGxweA==",
  },
  {
    title: "twitter",
    url: "https://x.com/rohittiwary309",
  },
  {
    title: "youtube",
    url: "https://www.youtube.com/@ashaindustries3802",
  },
  {
    title: "linkedin",
    url: "https://www.linkedin.com/in/rohit-tiwary-5527b4149?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
];
