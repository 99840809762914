import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { brandsList } from "../jsonFiles/brandsList";
import { Container, Divider } from "@mui/material";
import { MdEmail } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { Heading4, Heading6 } from "../components/global/Headings";
import ScrollToTop from "../components/global/ScrollToTop";

export default function BrandDetails() {
  const location = useLocation();
  const { brandId } = location?.state;
  const [brandDetails, setBrandDetails] = useState("");
  console.log(brandId);

  useEffect(() => {
    setBrandDetails(brandsList?.find((e) => e?._id === brandId));
  }, []);

  return (
    <div>
      <ScrollToTop />
      <Container maxWidth="lg">
        <div className="bg-white rounded-xl shadow shadow-shadowColor border border-borderColor">
          <div className="">
            <img
              src={brandDetails?.brandLogo}
              className="w-full rounded-xl rounded-b-none"
              alt="brand-logo"
            />
          </div>

          <div className="lg:mt-10 mt-6 p-2 md:p-4 ">
            <Heading4 title={brandDetails?.brandName} className="" />

            <Heading6 title={brandDetails?.slogan} />

            <div className="">
              {/* Overview */}
              <div className="lg:mt-10 mt-6">
                <Heading6 title="Overview" />
                <p className="mt-2 pl-4">{brandDetails?.overview}</p>
              </div>

              {/* Key Features */}
              <div className="lg:mt-10 mt-6">
                <Heading6 title="Key Features" />
                <div className="mt-2">
                  <ul className="list-inside list-disc">
                    {brandDetails?.keyFeatures?.map((e, i) => (
                      <li key={i} className="pl-4 mt-2">
                        {e}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Why Choose */}
              <div className="lg:mt-10 mt-6">
                <Heading6 title="Why Choose" />
                <div className="mt-2">
                  <ul className="list-inside list-disc">
                    {brandDetails?.whyChooseUs?.map((e, i) => (
                      <li key={i} className="pl-4 mt-2">
                        {e}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              {/* Where to Buy */}
              {/* <div>
              <p className="font-bold md:text-2xl sm:text-xl text-lg">Where to Buy:</p>
              <p className="mt-2 pl-4">
                <b>{brandDetails?.brandName}</b>? <b>{brandDetails?.brandName}</b> stands out in the market because of
                its dedication to [unique selling points]. Whether it’s
                [specific feature] or [another feature], customers choose [Brand
                Name] for its reliability and performance.
              </p>
            </div> */}

              {/* Contact Information */}
              <div className="lg:mt-10 mt-6">
                <Heading6 title="Contact Information" />
                <div className="mt-2 pl-4">
                  <div className="flex items-center gap-2">
                    <MdEmail />
                    <a href="mailto:contact@ashaind.in" rel="noreferrer">
                      contact@ashaind.in
                    </a>
                  </div>

                  <div className="flex items-center gap-2">
                    <FaMobileScreenButton />
                    <a href="tel:+918789857503" rel="noreferrer">
                      +91 8789857503
                    </a>
                  </div>
                </div>
              </div>

              <Divider sx={{ my: 4 }} />

              {/* Footer description */}
              <div className="text-sm">
                <p>{brandDetails?.footer}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
