import React from "react";

export default function SubHeading({ title }) {
  return (
    <div>
      <p className="bg-gradient-to-b uppercase from-primary via-rose-600 to-secondary bg-clip-text text-transparent text-xs">
        {title}
      </p>
    </div>
  );
}
